<template>
  <div
    class="search-header"
    @click="$refs.input.focus()"
  >
    <div
      class="search_box"
    >
      <i class="icon-joyo search_icon">&#xe6f0;</i>
      <form
        class="search_input"
        action="javascript:void 0"
      >
        <input
          ref="input"
          v-model="value"
          class="autofocus"
          :placeholder="placeholder"
          maxlength="50"
          @keyup.13="getLists"
          @input="getLists"
        >
      </form>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    placeholder: {
      type: String,
      default: '请输入搜索内容'
    },
    ruleFunc: {
      type: Function,
      default () {
        return true;
      }
    }
  },
  data() {
    return {
      value: ''
    };
  },
  methods: {
    getLists() {
      if (this.ruleFunc(this.value)) {
        this.$emit('getLists', this.value);
      }
    }
  }
};
</script>

<style  lang="scss"  scoped>
.search-header{
  height:58px;
  overflow: hidden;
  background-color: $color-FFF;
}
.search_box{
  display: flex;
  flex: 1;
  align-items:center;
  justify-content:flex-start;
  height: 34px;
  margin: 16px 15px 8px 15px;
  background-color: #F5F5F7;
  border-radius:4px ;
  input {
    width: 100%;
    line-height: 1;
    background-color: rgba(0, 0, 0, 0);
    border: 0;  // 去除未选中状态边框
    outline: none; // 去除选中状态边框// 透明背景
  }
  .search_icon{
    width:24px;
    height: 24px;
    margin:0 8px 0 6px;
    color:$color-CCC;
    font-size:$font-14 ;
    line-height: 24px;
    text-align: center;
  }
  .search_input{
    flex: 1;
    font-size: 12px;
  }
}
</style>
